<template>
  <a-card :bordered="false">
    <a-form :model="where" :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }">
      <a-row>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="姓名:" name="name">
            <a-input v-model:value.trim="where.name" placeholder="请输入姓名" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="电话:" name="phone">
            <a-input v-model:value.trim="where.phone" placeholder="请输入电话" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="奖项名称:" name="luckName">
            <a-input v-model:value.trim="where.luckName" placeholder="请输入奖项名称" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- 表格 -->
    <ud-pro-table ref="table" row-key="luckId" :datasource="datasource" :columns="columns" v-model:selection="selection"
      :scroll="{ x: 'max-content' }">
      <template #toolbar>
      </template>
      <template #pic="{ record }">
        <div class="image">
          <a-image :src="record.goodsPic" :width="120" :height="90"></a-image>
        </div>
      </template>
      <template #action="{ record }">
        <a-space>
          <a-popconfirm title="确定要取消中奖此项吗？" @confirm="cancel(record)">
            <a class="ud-text-danger">取消中奖</a>
          </a-popconfirm>
        </a-space>
      </template>
    </ud-pro-table>
  </a-card>
  <luck-user-cancel v-model:visible="showEdit" :data="current" :sceneId="sceneId" @done="reload"></luck-user-cancel>
</template>

<script>
import * as luckUserApi from '@/api/scene/luckUser'
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import luckUserCancel from './index-cancel'
export default {
  name: 'luck-index',
  props: {
    // 会场ID
    sceneId: Object,
    screenId: Object
  },
  components: {
    luckUserCancel
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        // {
        //   title: '会场活动名称',
        //   dataIndex: 'sceneName',
        //   sorter: true
        // },
        {
          title: '奖项名称',
          dataIndex: 'luckName',
          sorter: true
        },
        {
          title: '姓名',
          dataIndex: 'name',
          sorter: true
        },
        {
          title: '电话',
          dataIndex: 'phone',
          sorter: true
        },
        {
          title: '状态',
          dataIndex: 'state',
          sorter: true

        },
        {
          title: '开奖时间',
          dataIndex: 'lotteryTime',
          sorter: true

        },
        {
          title: '领奖时间',
          dataIndex: 'receiveTime',
          sorter: true

        },
        {
          title: '奖品名称',
          dataIndex: 'goodsName',
          sorter: true

        },
        {
          title: '奖品图片',
          dataIndex: 'goodsPic',
          slots: { customRender: 'pic' }
        }
        // ,
        // {
        //   title: '操作',
        //   width: 200,
        //   align: 'center',
        //   slots: { customRender: 'action' }
        // }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示现场
      showEdit: false,
      // 是否显示抽奖
      showLuck: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      detailInfo: null,
      // 编辑表单提交状态
      editLoading: false,
      corpTypeList: []
    }
  },
  watch: {
    sceneId() {
      this.reload()
    },
    screenId() {
      this.reload()
    }
  },
  methods: {
    datasource(option, callback) {
      luckUserApi
        .page({
          sceneId: this.sceneId,
          screenId: this.screenId,
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 搜索 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1, where: this.where })
    },
    /*  重置搜索 */
    reset() {
      this.where = {}
      this.reload()
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      this.$http.delete('/sys/user/' + row.userId).then(res => {
        hide()
        if (res.data.code === 0) {
          this.$message.success(res.data.msg)
          this.reload()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.message)
      })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的用户吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          this.$http.delete('/sys/user/batch', {
            data: this.selection.map(d => d.userId)
          }).then(res => {
            hide()
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              this.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            hide()
            this.$message.error(e.message)
          })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    }
  }
}
</script>

<style lang="less" scoped>
.ant-modal-body {
  padding: 0;
}

:deep(.ant-image-img) {
  height: 100%;
}
</style>
